import React, { Component } from "react";
import styled from "styled-components";
import TextContent from "./TextContent";
import ImageContent from "./ImageContent";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  /* border: 1px dashed red; */
`;

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vh: 0,
      slideNumber: 0,
    };
    this.pageSplitTimes = 1.4;
    this.lastScrollTop = 0;
    this.scrollDirectionDown = true;
    this.handleScroll = this.handleScroll.bind(this);
    this.workDetails = [
      {
        number: "",
        projectName: "",
        projectDesc: "",
        projectType: "",
        roles: [""],
      },
      {
        number: "01",
        projectName: "Belle-by-Essy",
        projectDesc:
          "A responsive web app for both web and mobile developed with React, Node.JS and CSS, and deployed on the Vercel platform",
        projectType: "WEB APP",
        roles: ["Web Development"],
      },
      {
        number: "02",
        projectName: "Harvest Alert",
        projectDesc:
          "A REST API for web and mobile app integrations developed using Python, Django Rest, Celery, AWS (Elastic Beanstalk, EC2, CodePipeline, S3), Docker and Postgres DB",
        projectType: "API",
        roles: ["Software Development"],
      },
      {
        number: "03",
        projectName: "Ecommerce",
        projectDesc:
          "An ecommerce and CRM developed with HTML, CSS, React, Django, Celery, AWS(Elastic Beanstalk, EC2, CodePipeline, S3), Postgres and Docker.",
        projectType: "WEB APP",
        roles: ["Web Development"],
      },
      {
        number: "04",
        projectName: "African Dyslexia",
        projectDesc:
          "Maintaining a wordpress web application dedicated to championing awareness about dyslexia and advocating for the rights of persons with dyslexia in Africa.",
        projectType: "WEB APP",
        roles: ["Volunteer"],
      },
      {
        number: '05',
        projectName: 'Database & AWS Infrastructure Design',
        projectDesc: 'Designed the implemented a backend infrastructure to host QnC webstore on AWS .',
        projectType: 'WEB APP',
        roles: ['Database Management'],
      },
      {
        number: '06',
        projectName: '10xbdimensional ',
        projectDesc: 'Configured the web application for deployment.',
        projectType: 'WEB APP',
        roles: ['Cloud Deployment'],
      },
      {
        number: "",
        projectName: "",
        projectDesc: "",
        projectType: "",
        roles: [""],
      },
    ];
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.setState({
      vh: Math.round(
        window.document.documentElement.clientHeight * this.pageSplitTimes
      ),
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    const { body, documentElement } = event.srcElement;
    const { vh, slideNumber } = this.state;
    const scrollDistance = Math.max(body.scrollTop, documentElement.scrollTop);
    if (scrollDistance > this.lastScrollTop) {
      this.scrollDirectionDown = true;
    } else {
      this.scrollDirectionDown = false;
    }
    this.lastScrollTop = scrollDistance;
    console.log(scrollDistance);

    if (
      Math.floor(scrollDistance / vh) !== slideNumber &&
      slideNumber < this.workDetails.length - 1
    ) {
      this.setState({ slideNumber: Math.floor(scrollDistance / vh) });
    } else if (
      slideNumber === this.workDetails.length - 1 &&
      Math.floor(scrollDistance / vh) < slideNumber
    ) {
      this.setState({ slideNumber: Math.floor(scrollDistance / vh) });
    }
  }

  changeTextContentBasedOnScroll() {
    const { slideNumber } = this.state;
    const refresh = true;
    return (
      <TextContent
        number={this.workDetails[slideNumber].number}
        projectName={this.workDetails[slideNumber].projectName}
        projectDesc={this.workDetails[slideNumber].projectDesc}
        projectType={this.workDetails[slideNumber].projectType}
        roles={this.workDetails[slideNumber].roles}
        refreshToggle={refresh}
      />
    );
  }

  render() {
    return (
      <Container>
        {this.changeTextContentBasedOnScroll()}

        <ImageContent pageSplitTimes={this.pageSplitTimes} />
      </Container>
    );
  }
}

export default Work;
